/* ====================== Calculate Miles ======================  */
  function calcDistance(p1, p2) {
    var km_distancing = (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
    var inMiles = km_distancing * .6214;
    var inMiles = inMiles.toFixed(2);
    return inMiles;
  }

/* ====================== Vars ======================  */
  var rowContent = "";
  var gmarkers = [];
  var map = null;
  var mapsignle = null;
  var circle = null;
  var searchInputID = document.getElementById("nursery_address");
  var geocoder = new google.maps.Geocoder();

/* ====================== Search Markers ======================  */
  function createMarker(latlng,link,area,secondarea,ofsted,kp_check,bannerImg) {
    // info window content //
    if(kp_check == 1) {
      var kpname = 'Kids Allowed';
    }
    else {
      var kpname = 'Kids Planet';
    }
    var contentString =
    '<h4>'+ kpname + ' <br> ' + area + '</h4>'+
    '<h5>' + secondarea + '</h5>'+
    '<a href="'+link+'" class="red_btn_small">View Nursery</a>';
    // marker setup //
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      area: area,
      secondarea: secondarea,
      ofsted: ofsted,
      kp_check: kp_check,
      nurseryimg: bannerImg,
      link: link,
      icon: '/wp-content/themes/kidsplanet/library/images/pink_map_marker.png',
      //name: name,
      zIndex: Math.round(latlng.lat()*-100000)<<5
    });
    // infowindow //
    google.maps.event.addListener(marker, 'click', function() {
       infowindowRad.setContent(contentString);
       infowindowRad.open(map,marker);
     });
    // Save Marker //
    gmarkers.push(marker);
  }

/* ====================== Create Radius Section ======================  */
  function codeAddress() {
    // Get Vars, works for either search or onPage //
    var address = document.getElementById('nursery_address').value;
    var radius = parseInt(document.getElementById('radius').value, 10)*1000;
    var radiusZoom = document.getElementById('radius').value;

    // set zooms based on radius - 10,15,25,50 //
    if(radiusZoom == 10) {
      map.setZoom(12);
    }
    if(radiusZoom == 15) {
      map.setZoom(12);
    }
    if(radiusZoom == 25) {
      map.setZoom(11);
    }
    if(radiusZoom == 50) {
      map.setZoom(10);
    }

    // clear anything already here //
    rowContent = '';

    // Populate content and markers //
    geocoder.geocode( { 'address': address}, function(results, status) {
      // if all good continue //
      if (status == google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        // internal vars //
        var searchCenter = results[0].geometry.location;
        var searchNiceName = results[0].address_components[0].short_name;

        if (circle) circle.setMap(null);
        circle = new google.maps.Circle({
          center:searchCenter,
          zoom:7,
          radius: radius,
          fillOpacity: 0.15,
          strokeColor: '#EC6285',
          strokeOpacity: 1,
          strokeWeight: 0,
          fillColor: "#EC6285",
          map: map
        });
        var bounds = new google.maps.LatLngBounds();
        var foundMarkers = 0;
        var foundMarkerSetup = []; // setup blank array for new sort //

        for (var i=0; i<gmarkers.length;i++) {
          if (google.maps.geometry.spherical.computeDistanceBetween(gmarkers[i].getPosition(),searchCenter) < radius) {
            // Calculate distance //
            var distance_calc = calcDistance(searchCenter,gmarkers[i].position);
            // Kids Allowed? //
            if(gmarkers[i].kp_check == 1) {
              var kpname = 'Kids Allowed';
            }
            else {
              var kpname = 'Kids Planet';
            }
            // ofsted //
            if(gmarkers[i].ofsted == 'Registered') {
              var ofstedShow = 'Oftsed Registered';
            }
            else {
              var ofstedShow = 'Ofsted Rating: <strong>'+gmarkers[i].ofsted+'</strong>';
            }
            var nurseryimg = gmarkers[i].nurseryimg;
            var nuserlocation = gmarkers[i].area;
            var nuserseclocation = gmarkers[i].secondarea;
            var nusrlink = gmarkers[i].link;

            // array push for array sort //
            foundMarkerSetup.push({
              kp_type: kpname,
              ofsted_set: ofstedShow,
              img: nurseryimg,
              distance: distance_calc,
              distancefrom: searchNiceName,
              areaone: nuserlocation,
              areatwo: nuserseclocation,
              nurserlink: nusrlink
            });
            // setup marker //
            (gmarkers[i].getPosition())
            gmarkers[i].setMap(map);
            // run and repeat //
            foundMarkers++;
            // // content //
            // var contentPop =
            // '<div class="map_card_wrapper">' +
            //   '<div class="imgTopWrap" style="background-image:url('+gmarkers[i].nurseryimg+');"></div>'+
            //     '<h3>'+ kpname + '<br>' + gmarkers[i].area + '</h3>'+
            //     '<h4 class="locationer">'+gmarkers[i].secondarea+'</h4>'+
            //     '<p>'+ofstedShow+'</p>'+
            //     '<p>Distance from '+searchNiceName+': <strong>'+distance_calc+'miles</strong></p>' +
            //     '<a href="'+gmarkers[i].link+'" class="red_btn_small">View Nursery</a>'+
            //   '</div>' +
            // '</div>';
           } else {
             // set the rest of markers to null //
             gmarkers[i].setMap(null);
           }
          // Populate message of how many have been found //
          document.getElementById("showNurseryCount").innerHTML = '<strong>'+foundMarkers+' nurseries</strong> within';
         }
         // sort by miles order //
         foundMarkerSetup.sort(function (x, y) {
           return x.distance - y.distance;
         });
         // populate found devs //
         for (var i=0; i<foundMarkerSetup.length;i++) {
           // content //
           var contentPop =
           '<div class="map_card_wrapper">' +
             '<div class="imgTopWrap" style="background-image:url('+foundMarkerSetup[i].img+');"></div>'+
               '<h3>'+ foundMarkerSetup[i].kp_type + '<br>' + foundMarkerSetup[i].areaone + '</h3>'+
               '<h4 class="locationer">'+foundMarkerSetup[i].areatwo+'</h4>'+
               '<p>'+foundMarkerSetup[i].ofsted_set+'</p>'+
               '<p>Distance from '+foundMarkerSetup[i].distancefrom+': <strong>'+foundMarkerSetup[i].distance+'miles</strong></p>' +
               '<a href="'+foundMarkerSetup[i].nurserlink+'" class="red_btn_small">View Nursery</a>'+
             '</div>' +
           '</div>';
           rowContent += contentPop;
         }
         // nothing found //
         if (foundMarkers === 0) {
           rowContent = '<div class="nofound_result"><h4>Nothing found!</h4><p>Please check you have selected your desired location from the drop down menu in the location finder. If you have checked this and there are still no nurseries found, please try searching again with different criteria or take a look at all of our nurseries <a href="/find-a-nursery">here</a>.</p></div>';
         }
         // put the assembled side_bar_html contents into the side_bar div
         document.getElementById("found_results").innerHTML = rowContent;
       } else {
         // alert('Geocode was not successful for the following reason: ' + status);
         incorrectContent = '<div class="nofound_result"><h4>Nothing found!</h4><p>Please check you have selected your desired location from the drop down menu in the location finder. If you have checked this and there are still no nurseries found, please try searching again with different criteria or take a look at all of our nurseries <a href="/find-a-nursery">here</a>.</p></div>';
         document.getElementById("found_results").innerHTML = incorrectContent;
       }
    });
  }

/* ====================== Create Map ======================  */
  function initializeMap() {
    var radiusMap = {
      zoom: 9,
      center: new google.maps.LatLng(53.481308,-2.242336),
      mapTypeControl: true,
      mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
      navigationControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    map = new google.maps.Map(document.getElementById("mapWrapper"), radiusMap);
    // Check if landed via a search or not //
    let url = window.location.href;
    if(url.includes('?')) {
      codeAddress();
      // Read the XML data using function in download_xml.js - downloadURL()
      downloadUrl("/wp-content/uploads/mapmarkers/markers.xml", function(doc) {
        var xmlDoc = xmlParse(doc);
        var markers = xmlDoc.documentElement.getElementsByTagName("marker");
        for (var i = 0; i < markers.length; i++) {
           // position //
           var lat = parseFloat(markers[i].getAttribute("lat"));
           var lng = parseFloat(markers[i].getAttribute("lng"));
           var point = new google.maps.LatLng(lat,lng);
           // information //
           var link = markers[i].getAttribute("link");
           var area = markers[i].getAttribute("area");
           var secondarea = markers[i].getAttribute("secondarea");
           var ofsted = markers[i].getAttribute("ofsted");
           var kp_check = markers[i].getAttribute("kpka");
           var bannerImg = markers[i].getAttribute("bnrimg");
           // create the marker //
           var marker = createMarker(point,link,area,secondarea,ofsted,kp_check,bannerImg);
        }
      });
    }
    // not so show all by default //
    else {
      // Read the XML data using function in download_xml.js - downloadURL()
     downloadUrl("../../wp-content/uploads/mapmarkers/markers.xml", function(doc) {
        var xmlDoc = xmlParse(doc);
        var markers = xmlDoc.documentElement.getElementsByTagName("marker");
        for (var i = 0; i < markers.length; i++) {
          // position //
          var lat = parseFloat(markers[i].getAttribute("lat"));
          var lng = parseFloat(markers[i].getAttribute("lng"));
          var point = new google.maps.LatLng(lat,lng);
          // information //
          var link = markers[i].getAttribute("link");
          var area = markers[i].getAttribute("area");
          var secondarea = markers[i].getAttribute("secondarea");
          var ofsted = markers[i].getAttribute("ofsted");
          var kp_check = markers[i].getAttribute("kpka");
          var bannerImg = markers[i].getAttribute("bnrimg");
          // create the marker //
          var marker = createMarker(point,link,area,secondarea,ofsted,kp_check,bannerImg);
        }
      });
    }
  }

/* ====================== OnPage Submission ======================  */
  jQuery("#search_nursery_internal").on("submit", function(){
    // If search params found - remove them //
    var newURL = location.href.split("?")[0];
    window.history.pushState('object', document.title, newURL);
    // populate the map with the markers found in new search //
    codeAddress();
    // don't refresh the page //
    return false;
  })

/* ====================== InfoWindow ======================  */
  var infowindowRad = new google.maps.InfoWindow({
   size: new google.maps.Size(150,50)
  });

/* ====================== Autocomplete ======================  */
if(searchInputID){
  var options = {
     componentRestrictions: {
       country: 'uk'
     }
  };
  var input = document.getElementById('nursery_address');
  autocomplete = new google.maps.places.Autocomplete(input,options);
}
