jQuery(document).ready(function($) {
  /* ====================== Main Loader ======================  */
    $(window).load(function() {
      // Animate loader off screen
     $("#kp_site_loader").animate({
         // width: "toggle",
         opacity: "toggle"
     },1000);
   });
  /* ====================== TRANSITIONS ======================  */
    new WOW().init();
  /* ====================== SCROLLMAGIC ======================  */
    var controller = new ScrollMagic.Controller();
    if($(window).width() > 580) {
      /* ======= Sticky Shapes =======  */
      // HOME //
      var movehpwoodsp = $("#nursery_in_numbers").outerHeight();
      var scene = new ScrollMagic.Scene({
          triggerElement: "#nursery_in_numbers",
          duration: movehpwoodsp,
      })
      .setPin("#home-banner-kp .woodenHp")
      // .addIndicators({name: "stick shape test"})
      .addTo(controller);
      // ABOUT //
      var moveaboutShape = $(".middleMid").outerHeight();
      var scene = new ScrollMagic.Scene({
          triggerElement: ".middleMid",
          duration: moveaboutShape + 400,
      })
      .setPin(".about_top_blue")
      // .addIndicators({name: "stick about shape"})
      .addTo(controller);
      // CAREERS //
      var moveaboutShape = $("#training_academy_cta").outerHeight();
      var scene = new ScrollMagic.Scene({
          triggerElement: "#training_academy_cta",
          duration: moveaboutShape + 450,
      })
      .setPin(".topShapeRight")
      // .addIndicators({name: "stick careers shape"})
      .addTo(controller);
      // PLAY AND LEARN //
      var movePlShape = $("#pah_news_related").outerHeight();
      var scene = new ScrollMagic.Scene({
          triggerElement: "#pah_news_related",
          duration: movePlShape + 275,
      })
      .setPin(".wood_pandl")
      // .addIndicators({name: "pandl careers shape"})
      .addTo(controller);
      /* ======= End Sticky Shapes =======  */

      /* ======= Move Shapes =======  */
      // Home Testimonials Shapes //
      var movetstshapes = TweenMax.fromTo(".testimonialStandWrap .shapeTpLeft", 1, { left: '0', top:'0' }, { left: '-60px', top:'-60px', ease: Power1.easeOut}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#testimonials_philos_hp .title",
          duration: 475,
          offset: -100
      })
      .setTween(movetstshapes)
      // .addIndicators({name: "Move testimonial shapes"})
      .addTo(controller);
      // Nursry Banner Wooden Shape //
      var moveNurswood = TweenMax.fromTo("#nurseryInner_banner .woodenSiN", 1, { right: '-0', bottom:'-150px' }, { right: '-260px', bottom:'-25px', ease: Power1.easeOut}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#nursery_overview",
          duration: 575
      })
      .setTween(moveNurswood)
      // .addIndicators({name: "Move home banner shapes"})
      .addTo(controller);
      // Find nursery Wooden Shape //
      var moveNurswood = TweenMax.fromTo("#map_Cards_Wrapper .woodfindhmm", 1, { right: '0' }, { right: '-260px', ease: Power1.easeOut}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#map_outputWrap",
          duration: 575,
          offset: -100
      })
      .setTween(moveNurswood)
      // .addIndicators({name: "Move home banner shapes"})
      .addTo(controller);
      /* ======= End Move Shapes =======  */

      /* ======= Our Story Timeline =======  */
      var timelineHeight = $("#our_story_timeline").outerHeight();
      var scene = new ScrollMagic.Scene({
          triggerElement: "#our_story_timeline",
          duration: timelineHeight - 525,
      })
      .setPin(".highlightInner")
      // .addIndicators({name: "Scroll bar indicatpr"})
      .addTo(controller);
    }
    // End Magic //

    if($(window).width() > 840) {
      $(".timeline_entry").each(function(i) {
        let yearTarget = $('.yearNum', this);
        var moveYear = TweenMax.fromTo(yearTarget, 1, { bottom: '-15px', left: '25px', opacity: '1' }, { bottom: '75px', left: '-35px', opacity: '0.5', ease: Power1.easeOut}  );
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "100%"
          // triggerHook: 0.5
        })
        .setTween(moveYear)
        .addTo(controller)
      });
    }

  /* ====================== Header ======================  */
    $(window).scroll(function() {
       var scroll = $(window).scrollTop();
       if (scroll >= 250) {
         $('#header').addClass("scrollheader_enabled");
       } else {
         $('#header').removeClass("scrollheader_enabled");
       }
    });

  /* ====================== Mobile Menu ======================  */
    $('.toggle-contain').click(function() {
      $('#mob_Menu_Overlay').addClass('open');
      $(this).addClass('hidetoggleKP');
      $("body").addClass("noscroll");
    });
    $('.close_HamMenu').click(function() {
      $('#mob_Menu_Overlay').removeClass('open');
      $('.toggle-contain').removeClass('hidetoggleKP');
      $("body").removeClass("noscroll");
    });
    // Mobile sub menu click //
    $("#mob_Menu_Overlay .mob_activate_sub").click(function(e) {
      e.preventDefault()
      $(this).parent().next('.sub-menu').toggleClass('show_mob_ddw');
      $(this).toggleClass('flipmob');
    });

   /* ====================== Enquiry Header ======================  */
     $("#header .enquire_header").click(function(e) {
        $('#enquiry_header_wrap').addClass('active_header_enquiry');
        $("body").addClass("noscroll");
     });
     $("#enquiry_header_wrap .close_HdrEnquire").click(function(e) {
        $('#enquiry_header_wrap').removeClass('active_header_enquiry');
        $("body").removeClass("noscroll");
     });

   /* ====================== Video Iframe ======================  */
     $(".openVideoOverlay").click(function(e) {
        $('#videoIframe').addClass('showVidiframe');
        $("body").addClass("noscroll");
     });
     $(".close_Vidframe").click(function(e) {
        $('#videoIframe').removeClass('showVidiframe');
        $("body").removeClass("noscroll");
     });

   /* ====================== VR Tour Iframe ======================  */
     $(".openTourOverlay").click(function(e) {
        $('#vrTourIframe').addClass('showVriframe');
        $("body").addClass("noscroll");
     });
     $(".close_Vrframe").click(function(e) {
        $('#vrTourIframe').removeClass('showVriframe');
        $("body").removeClass("noscroll");
     });

    /* ====================== Tabs ======================  */
    $('#tabs li a:not(:first)').addClass('inactive');
    $('.tabs_container').hide();
    $('.tabs_container:first').show();
    $('#tabs li a').click(function(e){
      var t = $(this).attr('href');
        $('#tabs li a').addClass('inactive');
        $(this).removeClass('inactive');
        $('.tabs_container').hide();
        $(t).fadeIn(300);
        // mobile //
        $('.mobileItemsWrap').removeClass('activatedMob').removeClass('showItemsMob');
        $('.mobileIco').removeClass('activeMobIco');

        // day in life text change //
        var daytype = $(this).attr('data-lifetype');
        var daytypeplural = $(this).attr('data-lifetype-plural');
        $('#daytype').html(daytype);
        $('#pluraldaytype').html(daytypeplural);

      e.preventDefault();
      return false;
    });
    if($(this).hasClass('inactive')) {
      $('#tabs li a').addClass('inactive');
      $(this).removeClass('inactive');
      $('.tabs_container').hide();
      $(t).fadeIn(300);
    }
    // mobile tab toggle //
    $('.mobileIco').click(function(e){
      $('.mobileItemsWrap').toggleClass('showItemsMob');
      e.preventDefault();
      return false;
    });
    $('.mobileItemsWrap a').click(function(e){
      var t = $(this).attr('href');
      $('#tabs li a').addClass('inactive');
      $(this).removeClass('inactive');
      $('.tabs_container').hide();
      $(t).fadeIn(300);
      $('.mobileIco').addClass('activeMobIco');
      $('.mobileItemsWrap').removeClass('showItemsMob');
      // active class //
      if(!$(this).parent().hasClass('activeMobIco')) {
        $('.mobileItemsWrap').removeClass('activatedMob');
        $(this).parent().addClass('activatedMob');
      }
      // day in life text change //
      var daytype = $(this).attr('data-lifetype');
      var daytypeplural = $(this).attr('data-lifetype-plural');
      $('#daytype').html(daytype);
      $('#pluraldaytype').html(daytypeplural);
      e.preventDefault();
      return false;
    });

  /* ====================== Accordion ======================  */
    $('li.question').on('click',function () {
      // don't move if is open //
      if ($(this).next().hasClass("openedFaQ")) {
        // close opened if clicked again //
        $(this).removeClass('openedQuestion');
        $(this).next().slideUp().removeClass("openedFaQ");
      }
      else {
        // plus, minus effect //
        $('li.question').removeClass('openedQuestion');
        $(this).addClass('openedQuestion');
        // remove anything already open //
        $('li.answer').slideUp().removeClass("openedFaQ");
        // add open state to the correct item //
        $(this).next().slideToggle(325).addClass('openedFaQ').siblings('li.answer').slideUp();
      }
    });

   /* ====================== Carousels ======================  */
      // Home Banner Carousel //
      $('#hp_caroBnr').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay:true,
        autoplayTimeout: 5000,
        animateOut: 'fadeOut',
        touchDrag: false,
        pullDrag:false,
        freeDrag:false
      });
      // -- Controls -- //
      var kphomebnr = $('#hp_caroBnr');
      kphomebnr.owlCarousel();
      $('.nextHpbnr').click(function() {
        kphomebnr.trigger('next.owl.carousel');
      })
      $('.prevHpbnr').click(function() {
        kphomebnr.trigger('prev.owl.carousel');
      });
      // End Home Banner Carousel //
      // About Us Banner Carousel //
      $('#about_caroBnr').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        touchDrag: false,
        pullDrag:false,
        freeDrag:false
      });
      // -- Controls -- //
      var kpabtbnr = $('#about_caroBnr');
      kpabtbnr.owlCarousel();
      $('.nextHpbnr').click(function() {
        kpabtbnr.trigger('next.owl.carousel');
      })
      $('.prevHpbnr').click(function() {
        kpabtbnr.trigger('prev.owl.carousel');
      });
      // End About Us Banner Carousel //
      // Testimonial Carousel //
      $('#testimonial_caro').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1
      });
      // End Testimonial Carousel //
      // Staff Career Testimonial Carousel //
      $('#staff_career_testimonials').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        touchDrag: false,
        pullDrag:false,
        freeDrag:false,
        autoHeight: true,
      });
      // -- Controls -- //
      var kpstafftbnr = $('#staff_career_testimonials');
      kpstafftbnr.owlCarousel();
      $('.nextStaffTbnr').click(function() {
        kpstafftbnr.trigger('next.owl.carousel');
      })
      $('.prevStaffTbnr').click(function() {
        kpstafftbnr.trigger('prev.owl.carousel');
      });
      // End Staff Career Testimonial Carousel //
      // Nursery Gallery //
      $('#nurseryGallery').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        responsive:{
         0:{
           items:1,
           touchDrag: false,
           pullDrag:false,
           freeDrag:false
         },
         600:{
           items:2,
           margin:20,
         },
         800:{
           items:3,
           margin:20,
         }
       }
      });
      // -- Controls -- //
      var kpNurseGal = $('#nurseryGallery');
      kpNurseGal.owlCarousel();
      $('.nextNursGal').click(function() {
        kpNurseGal.trigger('next.owl.carousel');
      })
      $('.prevNursGal').click(function() {
        kpNurseGal.trigger('prev.owl.carousel');
      });
      // End Testimonial Carousel //

      // HomePage Boxes Carousel //
      $('#hp_boxes_numbers').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        responsive:{
         0:{
           items:1,
           touchDrag: false,
           pullDrag:false,
           freeDrag:false
         },
         600:{
             items:2,
             margin:20,
         }
       }
      });
      // About Boxes Carousel //
      $('#about_boxes_numbers').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        responsive:{
         0:{
           items:1,
           touchDrag: false,
           pullDrag:false,
           freeDrag:false
         },
         600:{
             items:2,
             margin:20,
         }
       }
      });

  /* ====================== Misc ======================  */
    // map loader //
    $(window).load(function() {
      setTimeout(function()  {
        $('#mapResultLoading').fadeOut("slow");
      }, 650);
    });
    // find nursery submit //
    $("#search_nursery_internal").submit(function(e){
      $('html, body').animate({
         scrollTop: $("#found_results").offset().top - 65
       }, 750);
      return false;	// do not submit the form
      e.preventDefault();
    });

  /* ======================  CF7 ======================  */
    /* Validation Events for changing response CSS classes */
    document.addEventListener( 'wpcf7invalid', function( event ) {
      $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
      $('.wpcf7-response-output').addClass('alert-form alertform-danger');
    }, false );
    document.addEventListener( 'wpcf7spam', function( event ) {
      $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
      $('.wpcf7-response-output').addClass('alert-form alertform-warning');
    }, false );
    document.addEventListener( 'wpcf7mailfailed', function( event ) {
      $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
      $('.wpcf7-response-output').addClass('alert-form alertform-warning');
    }, false );
    document.addEventListener( 'wpcf7mailsent', function( event ) {
      $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
      $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
      $('.wpcf7-response-output').addClass('alert-form alertform-success');
    }, false );
});
