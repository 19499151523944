// Single developments - single location map
function initializeNurseryMap() {
  // setup lat & long
  var recentrelat = jQuery('#map_locatorID').attr('data-latit');
  var recentrelong = jQuery('#map_locatorID').attr('data-longi');
  // put into google LatLng
  var thisLatlng = new google.maps.LatLng(recentrelat,recentrelong);
  // setup map
  var singMap = {
    zoom: 14,
    center: thisLatlng,
    mapTypeControl: false,
    mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
    navigationControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  }
  mapsignle = new google.maps.Map(document.getElementById("mapNurseryInner"), singMap);
  var marker = new google.maps.Marker({
      position: thisLatlng,
      icon: '/wp-content/themes/kidsplanet/library/images/pink_map_marker.png'
  });
  marker.setMap(mapsignle);
}
